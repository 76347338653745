<template>
  <v-container
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-sale"
    >
      <template #title>
        Тип акції: <b>{{ (actionType[0] && actionType[0].name) }} (id: {{ actionId }})</b> <br>
      </template>

      <ButtonComeBack class="ml-3 mb-3" />


        <v-row>
          <v-col cols="12" lg="6">
            <v-form class="form-action-edit" lazy-validation v-model="isValidForm" ref="formAction">
              <!--Включено-->
              <v-col cols="12">
                <v-checkbox
                  v-model="enabled"
                  label="Включено"
                ></v-checkbox>
              </v-col>

              <!--Табы-->
              <v-col cols="12">
                <v-tabs v-model="tab" show-arrows>
                  <v-tab v-for="local in localization" 
                    :key="local.lang_key">
                    {{ local.name }}
                  </v-tab>
                </v-tabs>
              </v-col>

              <!--Содержымое табов-->
              <v-col cols="12">
                <v-tabs-items v-model="tab">
                  <v-tab-item 
                    v-for="(item, index) in actionType" 
                    :key="item.lang_key" 
                    :eager="true">
                    <template>
                      <!--Название-->
                      <v-text-field
                        label="Назва"
                        v-model="item.name"
                        required
                        :rules="[v => !!v || errorRequired]"
                      />
                      <!--Картинка-->
                      <ImageUpload 
                        v-model="item.image" 
                        @image-upload:change="changeImageList($event, item.lang_key)" 
                        :imageBaseUrl="imageBaseUrl"
                      />
                    </template>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              
              <!--Кнопки-->
              <v-col cols="12" class="text-right">
                <v-btn 
                  color="primary" 
                  min-width="150" 
                  @click="updateActionType()">
                  Зберегти
                </v-btn>
              </v-col>
              <Preloader v-if="isSending" />
            </v-form>
            
          </v-col>
        </v-row>
    </material-card>
  </v-container>
</template>


<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { IMAGE_BASE_URL } from '@/env.config';
 
export default {
  name: 'PageActionTypeProfile',
  data() {
    return {
      // Состояние акции 
      enabled: false,
      // id акции
      actionId: null,
      // Активный таб
      tab: 0,
      // Типы акций 
      actionType: [],
      // Нужно vuetify для валидации формы
      isValidForm: true,
      // Сообщение об ошибке для обязательных полей
      errorRequired: `Це поле обов'язкове`,
      // Указывает на то что выполняется отправка формы
      isSending: false,
      // Изображения для отправки 
      imagesToSend: {},
      // Базовый url  картинки
      imageBaseUrl: `${IMAGE_BASE_URL}actions/action_type/`
    }
  },
  computed: {
    ...mapGetters(['snackbar', 'localization', 'isManager', 'isAdmin'])
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...mapActions(['getLocalization']),
    async init() {
      await this.getLocalization();
      await this.getActionType();
    },

    // Получить тип акции
    async getActionType() {
      let { data } = await HTTP.post('/get-action-type', { 'type_id': this.$route.params.id  });
      let { enabled, id, langs } = data.content;
      this.enabled = enabled ? true : false;
      this.actionId = id;
      this.actionType = langs.map((el) => {
        el.image = el.image ? `${this.imageBaseUrl}${el.image}` : '';
        return el;
      });
    },

    // Изменить список картинок 
    changeImageList(data, langKey) {
      if (data.action) {
        this.imagesToSend[langKey] = {
          sendImage: data.sendImage
        };
      } else {
        delete this.imagesToSend[langKey];
      }
    },

    // Создать список ошибок
    createListErrors() {
      let errors = '';
      for(let item of this.actionType) {
        if (!item.name) {
          errors += `Помилка у мові ${item.lang_key}</br>`;
        }
      }
      return errors;
    },

    // Подготовка данных для отправки (filter проверяет наличие изменений langs)
    preparingDataSending() {
      let langs = this.actionType
        .map((el) => {
          return { lang_key: el.lang_key, name: el.name };
        });

      return {
        langs,
        id: this.actionId,
        enabled: this.enabled ? 1 : 0,
      }
    },

    // Создание типа акций
    async updateActionType() {
      let queryParams = this.preparingDataSending();

      if (this.isSending) return;

      if (!this.$refs.formAction.validate()) {
        this.setSnackbar({ type: 'error', text: this.createListErrors() });
        return;
      }

      this.isSending = true;

      try {
        await HTTP.post('/update-action-type', queryParams);
        await this.getActionType();

        let isImages = Object.keys(this.imagesToSend).length;

        this.setSnackbar({ 
          type: 'success', 
          text: `Тип тип акції оновлено. ${ isImages ? 'Виконується завантаження зображень.': '' }` 
        });

        if (!isImages) {
          this.isSending = false;
        }
        // сделал через $watch потомучто setSnackbar сделан через "mutation" (не может вернуть промис)
        const unwatch = this.$watch('snackbar.model', function (newVal, oldVal) {
          unwatch();
          // Запускаем загрузку изображений
          if (isImages) {
            this.runProcessImagesSend(this.actionId);
          }
        });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        this.isSending = false;
      }
    },

    // Запускает процесс отравки изображений, отправкой занимается "ImageUpload"
    async runProcessImagesSend(actionTypeId) {
      let errors = '';
      for (let key in this.imagesToSend) {
        let item = this.imagesToSend[key];
        let queryParams = {
          lang_key: key,
          object_image_id: actionTypeId,
          image_type: 'action_type_image',
        };
        let result = await item.sendImage(queryParams);
        // Если при загрузке изображений были - показать попап
        if (!result.error) {
          delete this.imagesToSend[key];
        } else {
          errors += `${result.error}</br>`;
          this.setSnackbar({ type: 'error', text: errors });
        }
      }
      this.isSending = false;
    },
  },
}
</script>

<style scoped lang="sass">
.form-action-edit
  position: relative
</style>

